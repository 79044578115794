#root{
  height: 50vh !important;
}
div[data-class="header-box"]{
 height: 25vh;
 overflow-y: scroll;
}

div[data-class="container-profile"]{
  background-color: rgb(249, 249, 249) !important;
  border-style: none !important;

}

div[data-class="applayout-container"]{
  height: 90vh;
  overflow-y: scroll;
  overflow: hidden;
  
}

div[data-class="layout-container"]{
  height: 90vh;
  border-radius: 0px !important;
  border-style: none !important;
}

div[data-class="skill-tab-container"]{
  overflow-y: scroll !important;
  border-radius: 0px !important;
  border-style: none !important;
  height: 100vh;
  scrollbar-width: 'none' !important;
  background-color: transparent !important;
}
div[data-class="skill-tab-container"]::-webkit-scrollbar {
  display: none;
}
.date-class{
  color: #000;
}

.date-class-mobile{
  color: #fff;
}

.flag{
  width: '15px' !important;
  height: '15px' !important;
}